// 全局变量配置
@import './mixin.scss';

// 通用色彩
$base-color-theme: #4d6bff; // 主题色
$base-color-primary: #4d6bff;
$base-color-success: #19be6b;
$base-color-warning: #ffac05;
$base-color-danger: #e5662e;
$base-color-error: #e5662e;
$base-color-info: #909399;
$base-color-text: #303030;
$base-color-background: #f3f3f5; // 默认背景色
$base-color-scrollbar: #d8dee6; // 默认滚动条颜色
$base-color-border: rgba(170, 178, 193, 0.2); // 默认边框线条颜色

$menu-color-bg: #f3f8ff; // 菜单背景色
$base-font-size: 14px; // 默认字体大小
$base-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s; // 默认动画效果

// 大屏颜色
$bs-theme: #80a4ed;
$bs-border-color: #7c9ddf;
$bs-bg-header: rgba(7, 13, 24, 0.9);
$bs-bg: linear-gradient(180deg, rgba(124, 189, 255, 0.12) 0%, rgba(124, 189, 255, 0) 100%);

:export {
	themeColor: $base-color-theme;
}
