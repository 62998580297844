@import "~@/styles/variables/variables.scss";












































































































































































































































































































@import '../runLayout.scss';
.directive-detail-main {
	flex-direction: column;
	padding: 0 24px;
	.basic-info {
		border-bottom: 0.5px dashed #999999;
	}
	.directive-detail-table-show {
		flex: 1;
		padding-top: 30px;
		display: flex;
		flex-direction: column;
		height: 0;
		.table-header {
			font-size: 16px;
			font-weight: 600;
			color: #333333;
			padding-bottom: 20px;
			display: flex;
			justify-content: space-between;
		}
		.table-box {
			flex: 1;
			height: 0;
			display: flex;
			.search-box {
				border-right: 1px dashed #ccc;
				box-sizing: border-box;
				width: 300px;
				height: 100%;
				padding: 16px 24px 16px 0;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				margin-right: 16px;
				.search-items {
					.search {
						width: 100% !important;
					}
				}
				.search-btns {
					display: flex;
					justify-content: center;
					.el-button {
						width: 40%;
						& + .el-button {
							margin-left: 20px;
						}
					}
				}
			}

			.table-content {
				flex: 1;
				width: 0;
			}
		}
	}
}
::v-deep .desc {
	.el-textarea.is-disabled .el-textarea__inner {
		border: 1px solid #d9d9d9;
		background: #ffffff !important;
		color: #4e4e4e;
	}
}
