// 定义mixin变量

// 滚动条默认样式，$offset参数可设置滚动条距离右侧的偏移量，需带单位px
@mixin base-scrollbar($offset: 0) {
	// 滚动条背景，宽高
	&::-webkit-scrollbar {
		width: 8px + $offset;
		height: 8px + $offset;
		background-color: #f5f5f5;
	}
	// 滚动条轨道
	&::-webkit-scrollbar-track {
		border: none;
		background-color: #fff;
	}
	// 滚动条滑块
	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background: rgba($base-color-scrollbar, 0.6);
		box-shadow: -$offset 0 0 #fff inset;
		background-clip: padding-box;
		border-color: transparent;
		border-width: 1px;
		border-style: dashed;
		z-index: 9999;
	}
	&::-webkit-scrollbar-thumb:hover {
		cursor: pointer;
		border-width: 0;
		background-color: rgba($base-color-scrollbar, 1);
	}
}

// flex设置元素居中
@mixin flex-center() {
	display: flex;
	align-items: center;
	justify-content: center;
}

// 按钮基本样式
@mixin base-button($width: 80px, $height: 40px, $radius: 8px) {
	.el-button {
		width: $width;
		height: $height;
		line-height: $height;
		padding: 0;
		border-radius: $radius !important;
		color: #4e4e4e;
		font-size: 14px;
		border: 1px solid #d8d8d8;
	}
	.el-button--primary,
	.el-button--primary:focus {
		color: #fff;
		border: 1px solid #4d6bff;
	}
	.el-button--primary.is-plain {
		color: #4d6bff;
		&:hover,
		&:focus {
			color: #fff;
		}
	}
	.el-button--primary.is-disabled {
		border: 1px solid #a6b5ff;
	}
	.el-button--warning.is-plain {
		color: #ffac05;
		background: #ffffff;
		border: 1px solid #ffac05;
		&:hover {
			background-color: #fdf6ec;
		}
	}
}
