.page-layout {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.run-main {
	height: 0;
	flex: 1;
	display: flex;
	padding: 20px 25px 0 25px;
	flex-direction: column;
	.serach {
		display: flex;
		flex-wrap: wrap;
		.gc-custom-search {
			margin-right: 20px;
			margin-bottom: 14px;
		}
		.serach-ops {
			@include base-button;
		}
	}
	.table-show {
		padding-top: 6px;
		width: 100%;
		flex: 1;
		height: 0;
	}
}

.layout-dialog {
	@include base-button($height: 32px, $radius: 16px);
	::v-deep {
		.el-dialog__body {
			padding: 0 24px 0 24px;
		}
		.desc {
			.el-textarea__inner {
				min-height: 96px !important;
			}
		}
	}
}
